import axios from "axios";
import he from "he";

const API_URL = process.env.REACT_APP_API_Link;

const headers = {
  "Content-Type": "multipart/form-data",
};

const headers2 = {
  "Content-Type": "application/json",
};

const loginAdmin = (values) => {
  return axios.post(
    API_URL + "admin-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers2,
    }
  );
};

const forgotPass = (values) => {
  return axios.post(
    API_URL + "admin-forgot",
    {
      email: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const getCounts = () => {
  return axios.get(
    API_URL + "all-counters-syg",

    {
      headers: headers2,
    }
  );
};

const latestOrder = () => {
  return axios.get(
    API_URL + "latest-orders-syg",

    {
      headers: headers2,
    }
  );
};
const orderArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-order/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const getProfile = (accesstoken) => {
  return axios.get(
    API_URL + "profile",

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accesstoken}`,
      },
    }
  );
};

const profileUpdate = (values, id) => {
  return axios.put(
    API_URL + `profile/${id}`,
    {
      email: values.email,
      password: values.old_password,
      new_password: values.password,
    },
    {
      headers: headers2,
    }
  );
};

const getWebsiteInfo = () => {
  return axios.get(
    API_URL + "website",

    {
      headers: headers2,
    }
  );
};

const websiteUpdate = (values) => {
  return axios.put(
    API_URL + "website",
    {
      email: values.email,
      phone: values.phone,
    },
    {
      headers: headers2,
    }
  );
};

const getAllYears = () => {
  return axios.get(API_URL + `list-years`, {
    headers: headers2,
  });
};

const getAllGifts = () => {
  return axios.get(API_URL + `all-products`, {
    headers: headers2,
  });
};

const getGiftsByRange = (start, end) => {
  return axios.get(
    API_URL + `active-products?start_points=${start}&end_points=${end}`,
    {
      headers: headers2,
    }
  );
};

const getGiftsStanly = () => {
  return axios.get(API_URL + `stanley-products`, {
    headers: headers2,
  });
};

const getAllGiftsForcetiId = (id) => {
  return axios.get(API_URL + `pavlina-product-certificate-syg/${id}`, {
    headers: headers2,
  });
};
const getAllLogos = () => {
  return axios.get(API_URL + `all-logos`, {
    headers: headers2,
  });
};

const getGifts = (page, limit, values, type = "all") => {
  return axios.get(
    API_URL +
      `products-syg?limit=${limit}&page=${page}&type=${type}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

const stanleyDeletedProducts = (page, limit, values) => {
  return axios.get(
    API_URL +
      `stanley-deactivated-products?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleGifts = (id, admin_id) => {
  return axios.delete(API_URL + "product/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllGifts = (admin_id) => {
  return axios.delete(API_URL + "products", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getGiftById = (id) => {
  return axios.get(
    API_URL + "product/" + id,

    {
      headers: headers2,
    }
  );
};
const updateGiftById = (values, id) => {
  return axios.post(API_URL + `update-product-syg/${id}`, values, {
    headers: headers,
  });
};

const addGift = (values) => {
  return axios.post(API_URL + `product-syg`, values, {
    headers: headers,
  });
};

const getOrders = (page, limit, values, status) => {
  return axios.get(
    API_URL +
      `orders-syg/${status}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleOrders = (id, admin_id) => {
  return axios.delete(API_URL + "order/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllOrders = (admin_id) => {
  return axios.delete(API_URL + "orders", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const archiveAllOrders = (admin_id) => {
  return axios.get(API_URL + "archive-all-orders", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};

// voucher apis

const getVouchers = (page, limit, values, status) => {
  return axios.get(
    API_URL +
      `vouchers-syg/${status}?limit=${limit}&page=${page}&denomination=${
        values?.minPoint || 0
      }&denomination_end=${values?.maxPoint || 0}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleVouchers = (id, admin_id) => {
  return axios.delete(API_URL + "voucher/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};

const voucherArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-voucher/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};
const voucherUnArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `unarchive-voucher/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const updateVoucherById = (
  values,
  adminId,
  id,
  selectedCards,
  startPoint,
  endPoint
) => {
  return axios.put(
    API_URL + `voucher-syg/${id}`,
    {
      company: values.Company,
      job: values.Job,
      certificate: values.Certificate,
      expiration: values.Expiration,
      products: selectedCards,
      fname: values.First,
      lname: values.Last,
      address: values.Street,
      city: values.City,
      state: values.State,
      zip: values.Zip,
      issuance: values.issuance,
      denomination: startPoint,
      denomination_end: endPoint,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

// EMAILS APIS

const deletSingleJobNo = (id, admin_id) => {
  return axios.delete(API_URL + "delete-job-syg/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};

const getEmails = (page, limit, values) => {
  return axios.get(
    API_URL +
      `emails?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleEmail = (id, admin_id) => {
  return axios.delete(API_URL + "email/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllEmail = (admin_id) => {
  return axios.delete(API_URL + "emails", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getEmailById = (id) => {
  return axios.get(
    API_URL + "email/" + id,

    {
      headers: headers2,
    }
  );
};
const updateEmailById = (email, id, adminId) => {
  return axios.put(
    API_URL + `email/${id}`,
    {
      email: email,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const addEmail = (email, adminId) => {
  return axios.post(
    API_URL + `email`,
    {
      email: email,
      adminId: adminId,
    },
    {
      headers: headers,
    }
  );
};

// logos section .

const getLogos = (page, limit, values) => {
  return axios.get(
    API_URL +
      `logos?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleLogos = (id, admin_id) => {
  return axios.delete(API_URL + "logo/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllLogos = (admin_id) => {
  return axios.delete(API_URL + "logos", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getLogoById = (id) => {
  return axios.get(
    API_URL + "logo/" + id,

    {
      headers: headers2,
    }
  );
};
const updateLogoById = (values, id) => {
  return axios.post(API_URL + `logo/${id}`, values, {
    headers: headers,
  });
};

const addLogo = (values) => {
  return axios.post(API_URL + `logo`, values, {
    headers: headers,
  });
};

const getVoucherById = (id) => {
  return axios.get(
    API_URL + "voucher/" + id,

    {
      headers: headers2,
    }
  );
};
const getVoucherByIdNew = (id) => {
  return axios.get(
    API_URL + "voucher-syg/" + id,

    {
      headers: headers2,
    }
  );
};

// job api

const getJobss = (page, limit, values) => {
  return axios.get(
    API_URL +
      `jobs-syg?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

const getJobDetailByJob = (page, limit, values, job) => {
  return axios.get(
    API_URL +
      `job-detail-syg/${job}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const getJobDetailInfoByJob = (job) => {
  return axios.get(API_URL + `job-detail-info/${job}`, {
    headers: headers2,
  });
};

const getJobNoProducts = (job) => {
  return axios.get(
    API_URL + "job-syg/" + job,

    {
      headers: headers2,
    }
  );
};

const updateJobProductByJob = (
  selectedCards,
  startPoint,
  endPoint,
  adminId,
  job
) => {
  return axios.put(
    API_URL + `job-syg/${job}`,
    {
      products: selectedCards,
      denomination: startPoint,
      denomination_end: endPoint,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const uploadSheet = (values) => {
  return axios.post(API_URL + `upload-sheet-syg`, values, {
    headers: headers,
  });
};
const fetchSheetDenom = (values) => {
  return axios.post(API_URL + `fetch-sheet-denomination-syg`, values, {
    headers: headers,
  });
};
const uploadSheetNew = (values) => {
  return axios.post(API_URL + `upload-sheet-syg-new`, values, {
    headers: headers,
  });
};
const uploadVouchersSheetNew = (values) => {
  return axios.post(API_URL + `oos-email`, values, {
    headers: headers,
  });
};

const getSubmittedSurvey = (page, limit, values, status) => {
  return axios.get(
    API_URL +
      `surveys/${status}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

const surveyArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-survey/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};
const getNewsletter = (orderId) => {
  return axios.get(
    API_URL + `newsletter/${orderId}`,

    {
      headers: headers2,
    }
  );
};

const newsletterUpdate = (values, admin_id, id) => {
  return axios.put(
    API_URL + "newsletter/" + id,
    {
      subject: values.subject,
      body: he.encode(values.body),
      adminId: admin_id,
    },
    {
      headers: headers2,
    }
  );
};

const getOrderById = (id) => {
  return axios.get(
    API_URL + "pavlina-certificate-syg/" + id,

    {
      headers: headers2,
    }
  );
};

const updateVoucherByIdByPavlina = (values, adminId, id) => {
  return axios.put(
    API_URL + `pavlina-record-syg/${id}`,
    {
      expiration: values.Expiration,
      issuance: values.issuance,
      company: values.Company,
      job: values.Job,
      certificate: values.Certificate,
      concatenated: values.Concatenated,
      amount: values.Amount,
      shipCost: values.shipCost,
      shipped: values.Shipped,
      received: values.Received,
      fulfilled: values.Fulfilled,
      fname: values.First,
      lname: values.Last,
      email: values.email,
      address1: values.address1,
      phone: values.phone,
      city: values.City,
      state: values.State,
      zip: values.Zip,
      status: values.CertiStatus,
      product: values.product,
      redemDate: values.redemDate,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const getCertificateBySearch = (values, page, limit) => {
  return axios.get(API_URL + `pavlina-record-syg`, {
    params: {
      company: values.Company,
      job: values.Job,
      certificate: values.Certificate,
      expiration: values.Expiration,

      tracking_id: values.order_no,
      status: values.CertiStatus,
      fname: values.First,
      lname: values.Last,
      address1: values.address1,
      email: values.email,
      phone: values.phone,
      city: values.City,
      state: values.State,
      zip: values.Zip,
      issuance: values.issuance,

      redemption_from: values.redemDate,
      redemption_to: values.redemption_to,
      product: values.product,
      limit: limit,
      page: page,
    },

    headers: headers2,
  });
};

const getManualOrders = (page, limit, values) => {
  return axios.get(
    API_URL +
      `vouchers-syg/manual?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

const AdminListService = {
  loginAdmin,
  forgotPass,
  getCounts,
  latestOrder,
  getProfile,
  profileUpdate,
  orderArchive,
  getWebsiteInfo,
  websiteUpdate,
  getGifts,
  stanleyDeletedProducts,
  getAllGifts,
  getGiftsStanly,
  getGiftsByRange,
  getAllGiftsForcetiId,
  getAllYears,
  getAllLogos,
  deletSingleGifts,
  deletAllGifts,
  getGiftById,
  updateGiftById,
  addGift,
  getOrders,
  deletSingleOrders,
  deletAllOrders,
  archiveAllOrders,
  voucherUnArchive,
  updateVoucherById,
  deletSingleVouchers,
  getVouchers,
  voucherArchive,
  getVoucherById,
  getVoucherByIdNew,
  getEmails,
  deletAllEmail,
  deletSingleEmail,
  getEmailById,
  updateEmailById,
  addEmail,
  getLogos,
  deletSingleLogos,
  deletAllLogos,
  getLogoById,
  updateLogoById,
  addLogo,
  getJobss,
  getJobDetailByJob,
  getJobDetailInfoByJob,
  getJobNoProducts,
  updateJobProductByJob,
  uploadSheet,
  getSubmittedSurvey,
  surveyArchive,
  getNewsletter,
  newsletterUpdate,
  getOrderById,
  updateVoucherByIdByPavlina,
  getCertificateBySearch,
  fetchSheetDenom,
  uploadSheetNew,
  uploadVouchersSheetNew,
  getManualOrders,
  deletSingleJobNo,
};

export default AdminListService;
